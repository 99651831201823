import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:id',
      name: 'home.page',
      component: () => import('@/pages/HomePage.vue'),
      meta: { transition: 'slide-home' },
    },
    {
      path: '/rate',
      name: 'rate.page',
      component: () => import('@/pages/RatePage.vue'),
      meta: { transition: 'slide-rate' },
    },
    {
      path: '/scan',
      name: 'scan.page',
      component: () => import('@/pages/ScanPage.vue')
    },
    {
      path: '/congrats',
      name: 'congrats.page',
      component: () => import('@/pages/CongratulationsPage.vue')
    }
  ]
})

export default router
