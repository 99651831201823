import './assets/main.css'
import 'primevue/resources/themes/aura-light-green/theme.css'
import * as Sentry from "@sentry/vue"

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import PrimeVue from 'primevue/config'
import Lara from '@/presets/lara'
import { VueQueryPlugin } from '@tanstack/vue-query'

import { MotionPlugin } from '@vueuse/motion'

import App from './App.vue'
import router from './router'

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)
const app = createApp(App)

Sentry.init({
  app,
  release: `${__APP_PACKAGE_NAME__}@${__APP_VERSION__}`,
  environment: import.meta.env.MODE,
  dsn: "",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "degust.qummy.top", /^\/api\/v[1-3]$/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


app.use(createPinia())
  .use(VueQueryPlugin)
  .use(MotionPlugin, {})
  .use(router)
  .use(PrimeVue, {
    unstyled: true,
    pt: Lara
  })

app.mount('#app')
