<script setup lang="ts">

import { ref } from 'vue'
import Card from "primevue/card"

const activeStep = ref<number>(0)
const stepsLength = ref<number>(0)

const onActiveStep = (v) => {
  activeStep.value = v
}

const onStepsLength = (v) => {
  stepsLength.value = v
}

const cardPtOptions = ref({
  mergeSections: true,
  mergeProps: true
});

const cardPassThrough = ref({
  body() {
    return {
      class: '!p-0 grow flex flex-col'
    }
  },
  content() {
    return {
      class: '!p-0 grow flex flex-col'
    }
  },
  footer() {
    return {
      class: 'mt-auto'
    }
  }
})
</script>

<template>
  <div class=" flex flex-col h-[100dvh] w-full lg:items-center">
    <div class="container px-2 z-10 mx-auto ">
      <div class="flex justify-center py-6">
        <h1 v-if="activeStep === 0 || activeStep === stepsLength" class="text-2xl text-white font-black">Дегустация</h1>
        <h1 v-else class="text-2xl text-white font-black">Вопрос {{ activeStep }} из {{ stepsLength - 1 }}</h1>
      </div>
    </div>
    <Card
      class="relative flex flex-col grow rounded-b-none overflow-hidden sm:rounded-b-3xl sm:m-5 lg:w-2/3 2xl:w-3/6 bg-opacity-70"
      :pt-options="cardPtOptions"
      :pt="cardPassThrough">
      <template #content>
        <RouterView v-slot="{Component, route}">
          <Transition>
            <Component
              :key="route.name"
              @activeStep="onActiveStep"
              @stepsLength="onStepsLength"
              :is="Component"
            />
          </Transition>
        </RouterView>
      </template>
    </Card>
  </div>
</template>

<style>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: all 1s ease;
}

.v-enter-from {
  transform: scale(0.5);
  opacity: 0;
}
.v-leave-to {
  transform: scale(1.8);
  opacity: 0;
}
</style>
